<template>
  <div class="voucher-container bg-nColorYellow">
    <div class="mainContent gap-4 justify-items-center text-center">
      <div class="flex flex-col justify-center items-center pt-8">
        <img :src="logo" class="w-1/3" />
        <img
          :src="require('@/assets/images/congratulations-text.svg')"
          class="w-4/6"
        />
        <div class="w-full px-8 pt-2" v-if="voucher">
          <div class="pb-3 rounded-xl font-InterRegular text-xs">
            <div class="text-left">
              Enter your email id to receive your voucher.
            </div>
            <input
              class="textInput mt-1"
              type="email"
              @input="handleInput"
              v-model="email"
              placeholder="Email"
            />
            <div class="w-full">
              <div class="flex justify-center">
                <button
                  @click.prevent="sendCouponCode()"
                  :class="[
                    'h-12 shadow-orange-right bg-black rounded-3xl w-full mt-2 text-white text-center py-1 uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                    { disabled: isSubmitLoading },
                  ]"
                  :disabled="isSubmitLoading"
                >
                  <span v-if="isSubmitLoading" class="flex justify-center">
                    <svg
                      width="60"
                      height="15"
                      viewBox="0 0 120 30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                    >
                      <circle cx="15" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                        <animate
                          attributeName="r"
                          from="9"
                          to="9"
                          begin="0s"
                          dur="0.8s"
                          values="9;15;9"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="0.5"
                          to="0.5"
                          begin="0s"
                          dur="0.8s"
                          values=".5;1;.5"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="105" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </svg>
                  </span>
                  <span v-else> SUBMIT </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-8 py-2" v-if="voucher && voucher.value">
          <div
            class="bg-white bg-opacity-40 py-3 rounded-xl"
            v-if="voucher.value"
          >
            <div class="font-ZuumeRegular text-xl">Voucher Value</div>
            <div class="font-ZuumeSemiBold text-3xl">₹{{ voucher.value }}</div>
          </div>
        </div>
        <div class="w-full px-8" v-if="voucher && voucher.redeemable_at">
          <div
            class="bg-white bg-opacity-40 py-3 rounded-xl font-ZuumeSemiBold text-3xl"
          >
            Redeemable At
            <div class="flex flex-wrap justify-center">
              <div
                v-for="place in voucher.redeemable_at"
                :key="place"
                class="w-1/2 p-2 flex justify-center"
              >
                <img :src="place" class="h-10 w-auto" />
              </div>
            </div>
            <div
              class="flex flex-wrap px-4 pt-2"
              v-if="voucher.locations.length > 1"
            >
              <div
                v-for="place in voucher.locations"
                :key="place"
                class="w-1/3 px-2 flex justify-center text-xs font-InterRegular"
              >
                {{ place }}
              </div>
            </div>
            <div
              class="flex flex-wrap px-4 pt-2 justify-center"
              v-else-if="voucher.locations.length === 1"
            >
              <div
                v-for="place in voucher.locations"
                :key="place"
                class="px-2 flex justify-center text-xs font-InterRegular"
              >
                {{ place }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-full px-8 py-2 pb-16"
          v-if="voucher && voucher.terms_conditions"
        >
          <div
            class="bg-white bg-opacity-40 py-3 rounded-xl font-InterExtraBold text-lg"
          >
            Terms and Conditions

            <ul class="list-disc ml-5 font-InterRegular text-xs text-left px-2">
              <li
                v-for="point in voucher.terms_conditions"
                :key="point"
                class="py-1"
              >
                {{ point }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="goto-game-button fixed bottom-0 w-screen bg-nColorOrange rounded-t-2xl z-20"
        >
          <div
            class="text-nColorDarkGray font-ZuumeSemiBold text-3xl flex justify-center items-center py-2 gap-2"
            @click.prevent="goBackToLobby"
          >
            <p>Next Game</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "Voucher",
  components: {},
  data() {
    return {
      businessCategory: this.$store.getters.businessCategory,
      logo: this.$store.getters.businessLogo,
      voucher: null,
      email: null,
      isSubmitLoading: false,
    };
  },
  created() {
    ApiService.post(apiResource.voucher.validateStatus, {
      business_slug: this.$route.params.business,
      schedule_id: this.$route.params.schedule_id,
    })
      .then((data) => {
        this.voucher = data.data.data.data;
      })
      .catch(() => {
        // this.goBackToLobby();
      });
  },
  mounted() {},
  methods: {
    goBackToLobby() {
      this.$router.push({
        name: "Lobby",
        params: {
          business: this.$route.params.business,
        },
      });
    },
    sendCouponCode() {
      this.isSubmitLoading = true;
      if (this.email) {
        ApiService.post(apiResource.voucher.sendCouponCode, {
          business_slug: this.$route.params.business,
          schedule_id: this.$route.params.schedule_id,
          email: this.email,
        })
          .then(() => {
            toast.success("Voucher sent successfully", { timeout: 2000 });
            setTimeout(() => {
              this.isSubmitLoading = false;
              this.goBackToLobby();
            }, 2000);
          })
          .catch(() => {
            this.isSubmitLoading = false;
            this.goBackToLobby();
          });
      } else {
        this.isSubmitLoading = false;
        toast.error("Please enter a valid email id", { timeout: 2000 });
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.voucher-container {
  height: 100dvh;
}
.textInput {
  @apply bg-white bg-opacity-70 border border-black rounded-lg block appearance-none focus:bg-nColorOrange focus:outline-none bg-transparent w-full py-1 px-2 placeholder-black font-ZuumeExtraBold text-center text-3xl tracking-wider;
}
.textInput::placeholder {
  @apply font-ZuumeSemiBold text-nColorOrange text-3xl tracking-normal;
}
.disabled {
  @apply bg-nColorBGDarkGray;
  pointer-events: none;
}
</style>